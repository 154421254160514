<template>
  <b-overlay
    :show="OverLay"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="auth-wrapper auth-v1 px-2 bg-login overflow-auto h-100">
      <div class="auth-inner py-2">
        <div class="text-center mb-2">
          <img
            src="/logo/Logo-Rich6.png"
            alt="logo"
            height="80"
          >
        </div>
        <b-card class="mb-0 pt-1 pb-1">
          <div>
            <b-card-text style="padding: 0 15px 15px 15px">
              <div>
                <validation-observer ref="loginForm">
                  <!-- email -->
                  <div v-if="!ref">
                    <b-form-group>
                      <label for="tel">เบอร์โทรศัพท์</label>
                      <validation-provider
                        #default="{ errors }"
                        name="tel"
                        rules="required|min:10"
                      >
                        <b-form-input
                          v-model="tel"
                          name="tel"
                          type="number"
                          placeholder="กรุณากรอกเบอร์โทรศัพท์"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          :state="errors.length > 0 ? false : null"
                          maxlength="10"
                          @keyup.enter="getOTPs()"
                        />
                        <small class="text-danger">{{
                          errors[0] ? "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก" : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      class="btn btn-theme-two btn2 btn-block btn-warning"
                      variant="warning"
                      @click="getOTPs"
                    >
                      ยืนยัน
                    </b-button>
                  </div>

                  <div v-if="ref && !match">
                    <div class="row">
                      <div class="col-4">
                        <div>
                          <label>&nbsp;</label><br>
                          <vue-captcha
                            ref="captcha"
                            :captcha.sync="code"
                            font-weight="bold"
                            @on-change="handleChange"
                          />
                        </div>
                      </div>
                      <div class="col-8">
                        <b-form-group>
                          <label
                            for="password"
                          >รหัส Captcha
                            <small
                              class="text-danger"
                            >ref : {{ ref }}</small></label>
                          <validation-provider
                            #default="{ errors }"
                            name="otp"
                          >
                            <b-form-input
                              id="otp"
                              v-model="otp"
                              name="otp"
                              type="otp"
                              placeholder="กรุณากรอกตัวอักษรที่แสดง"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              :state="errors.length > 0 ? false : null"
                              maxlength="4"
                              @keydown.enter.prevent="checkOTP"
                            />
                            <small class="text-danger">{{
                              errors[0] ? "กรุณากรอกรหัส OTP 6 หลัก" : ""
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>

                    <b-button
                      class="btn-theme-two"
                      block
                      style="margin-top: 23.5px"
                      variant="warning"
                      @click="checkOTP"
                    >
                      ยืนยัน
                    </b-button>
                  </div>
                </validation-observer>
              </div>

              <div v-if="!next && match && ref">
                <validation-observer ref="loginForm">
                  <b-form class="auth-login-form">
                    <!-- email -->
                    <b-form-group>
                      <label
                        for="password"
                      >ตั้งรหัส พาสเวิร์ด เข้าสู่ระบบ</label>
                      <validation-provider
                        #default="{ errors }"
                        name="password"
                        rules="required|min:6"
                      >
                        <b-form-input
                          v-model="password"
                          name="password"
                          type="password"
                          :state="errors.length > 0 ? false : null"
                          @keydown.enter.prevent="Next"
                        />
                        <small class="text-danger">{{
                          errors[0] ? "กรุณาตั้งรหัสผ่าน 6 ตัวขึ้นไป" : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group>
                      <label for="invited_by">รหัสแนะนำ</label>
                      <b-form-input
                        v-model="invited_by"
                        name="invited_by"
                        type="text"
                        disabled
                        @keydown.enter.prevent="Next"
                      />
                    </b-form-group>

                    <b-button
                      class="btn-theme-two"
                      block
                      style="margin-top: 23.5px"
                      variant="warning"
                      @click="Next"
                    >
                      ถัดไป
                    </b-button>
                  </b-form>
                </validation-observer>
              </div>

              <div v-if="next && match && ref">
                <!-- <div> -->
                <validation-observer ref="loginForm">
                  <b-form class="auth-login-form">
                    <!-- email -->
                    <b-form-group>
                      <label for="name">ชื่อ</label>
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="name"
                          name="name"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? "กรุณากรอกชื่อให้ถูกต้อง" : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group>
                      <label for="surname">นามสกุล</label>
                      <validation-provider
                        #default="{ errors }"
                        name="surname"
                        rules="required"
                      >
                        <b-form-input
                          v-model="surname"
                          name="surname"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? "กรุณากรอกนามสกุลให้ถูกต้อง" : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <div>
                      <small class="text-danger">
                        *กรอกชื่อ-นามสกุล ให้ตรงกับชื่อบัญชี หรือทรูมันนี่วอลเล็ท
                      </small>
                    </div>
                    <!-- <b-form-checkbox
                        v-model="wallet"
                        checked="fasle"
                        name="check-button"
                        switch
                        inline
                        class="custom-control-warning mb-1"
                      >
                        ทรูมันนี่วอลเล็ท
                      </b-form-checkbox> -->
                    <b-form-group
                      class="mb-1"
                    >
                      <label class="fg">หมายเลขทรูมันนี่วอลเล็ท</label>
                      <b-form-input
                        v-model="trueaccno"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10"
                        placeholder="กรุณากรอกหมายเลขทรูมันนี่วอลเล็ท"
                        type="number"
                        disabled
                      />
                    </b-form-group>

                    <!-- select bank -->
                    <b-form-group>
                      <label for="bank">เลือกธนาคาร</label>
                      <div
                        class="select_bank"
                        :style="`border: 2px solid ${
                          selected ? selected.bg : '#b4b7bd'
                        };`"
                        @click="showModal"
                      >
                        <img
                          :src="`/bankIcon/${
                            selected ? selected.path_photo : 'bk.png'
                          }`"
                          :style="`background-color: ${
                            selected ? selected.bg : '#FF6700'
                          };`"
                          class="bank_icon"
                        >
                        <span>{{
                          selected ? selected.name : "กรุณาเลือกธนาคาร"
                        }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <label for="accno">เลขที่บัญชี</label>
                      <b-form-input
                        v-model="accno"
                        name="accno"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="13"
                        type="number"
                      />
                    </b-form-group>

                    <!-- select bank -->
                    <b-form-group>
                      <label
                        for="knowby"
                      >รู้จักเราช่องทางใด
                        <small
                          class="text-warning"
                        >(รับสิทธิพิเศษ VIP)</small></label>
                      <b-form-select
                        v-model="knowby"
                        :options="options"
                        name="knowby"
                        required
                      />
                    </b-form-group>

                    <b-form-group>
                      <label
                        for="lineID"
                      >ไอดีไลน์
                        <small
                          class="text-warning"
                        >(รับสิทธิพิเศษ VIP)</small></label>
                      <b-form-input
                        v-model="lineID"
                        name="lineID"
                        type="text"
                        @keydown.enter.prevent="validationForm"
                      />
                    </b-form-group>

                    <b-button
                      class="btn-theme-two"
                      block
                      style="margin-top: 23.5px"
                      variant="warning"
                      @click="validationForm"
                    >
                      ยืนยันข้อมูล
                    </b-button>
                  </b-form></validation-observer>
              </div>
            </b-card-text>

            <b-modal
              ref="my-modal"
              centered
              title="Vertically Centered"
              hide-footer
              hide-header
              hide-header-close
            >
              <!-- <div class="bg-white rounded-3 p-1"> -->
              <div class="row">
                <div
                  v-for="option in bank"
                  :key="option.bankcode"
                  class="col-4 mb-2 mt-1 text-center"
                  @click="select(option)"
                >
                  <b-avatar
                    size="65"
                    :src="`/bankIcon/${option.path_photo}`"
                    :style="`background-color: ${option.bg}; padding: 10px; border: 4px solid #1e110d66; border-radius: 0;`"
                  />
                  <p style="color: #000">
                    {{ option.codename }}
                  </p>
                </div>
              </div>
            <!-- </div> -->
            </b-modal>
          </div>
        </b-card>
        <p class="text-center mt-1">หากมีบัญชีอยู่แล้ว? <a
          class="font-weight-bolder text-warning"
          @click="loginLink()"
        >
          เข้าสู่ระบบ คลิก!!
        </a></p>
        <b-card-text class="text-center mt-2">
          <span>Copyright © 2024 supported by RICH6</span><br>
          <small>เวอร์ชั่น 1.0.1</small>
        </b-card-text>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
// eslint-disable-next-line indent, comma-dangle
  BAvatar, BButton, BCard, BCardText, BForm, BFormGroup, BFormInput, BFormSelect,
  // BLink,
  BOverlay,
  BIconController, BModal,

} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueCaptcha from 'vue-captcha-code'

export default {
  components: {
    // BSV
    VueCaptcha,
    BCard,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BModal,
    BAvatar,
    // BLink,
    BOverlay,
    BIconController,
    // BFormCheckbox,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      code: '',
      getOTP: false,
      confirmPassword: false,
      lineID: '',
      otp: '',
      knowby: null,
      password: '',
      promo: '',
      wallet: '',
      promotion: '',
      surname: '',
      name: '',
      s_bank: null,
      accno: '',
      selected: null,
      invite_friend: '',
      fname: '',
      lname: '',
      trueaccno: '',
      bank: '',
      options: [
        { value: null, text: 'คุณรู้จักเราผ่านช่องทางใด' },
        { value: 1, text: 'Facebook' },
        { value: 2, text: 'Youtube' },
        { value: 3, text: 'Tik Tok' },
        { value: 4, text: 'Google' },
        { value: 5, text: 'SMS' },
        { value: 6, text: 'เพื่อนแนะนำ' },
      ],
      username: '',
      tel: '',
      status: '',
      // validation rules
      required,
      email,
      ag: 0,
      logo_path: '',
      ref: null,
      match: true,
      next: null,
      OverLay: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getagid()
    this.getBank()
    if (this.$route.params.invcode) {
      // console.log(this.$route.params.invcode)
      // this.knowby = 6
      this.invited_by = this.$route.params.invcode
    }
    if (this.$route.params.ag) {
      // console.log(this.$route.params.ag)
      this.ag = this.$route.params.ag
    }
    if (this.$route.params.noinv) {
      // console.log(this.$route.params.noinv)
      // this.knowby = 6
      this.noinv = this.$route.params.noinv
    }
    if (localStorage.getItem('invcode')) {
      // this.knowby = 6
      this.invited_by = localStorage.getItem('invcode')
    }
    if (this.$route.query.ref) {
      // this.knowby = 6
      this.invited_by = this.$route.query.ref
    }
    if (this.$route.query.register) {
      this.$router.push('/login')
    }
  },
  methods: {
    getagid() {
      const obj = {
        // site: window.location.origin,
        site: 'https://wallet.rich6-th.com',
      }
      this.$http
        .post('https://api.richgroup.vip/api/agent/showid', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.ag = 1
          localStorage.setItem('wallet_endpoint', 'https://api.richgroup.vip/api')
          localStorage.setItem('logo_path', response.data.logo_path)
          this.logo_path = response.data.logo_path
          this.show = false
        })
        .catch(error => console.log(error))
    },
    loginLink() {
      if (this.$route.query.ref) {
        this.$router.push({ name: 'login', query: { ref: this.$route.query.ref } })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    Next() {
      if (this.password) {
        this.next = true
      }
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.OverLay = true
          if (this.promo === false) {
            this.promotion = null
          }
          // if (this.wallet === true) {
          //   this.s_bank = 'truewallet'
          //   this.accno = null
          // } else {
          //   this.trueaccno = null
          // }
          // console.log(this.ag)
          const formData = {
            // username: `HIT${this.tel}`,npm install
            agent_id: 1,
            name: this.name,
            surname: this.surname,
            invited_by: this.$route.query.ref,
            tel: this.tel,
            bank: this.s_bank,
            acc_no: this.accno,
            password: this.password,
            knowby: this.knowby,
            main_promotion: this.promotion,
            trueaccno: this.trueaccno,
          }
          // console.log(formData)
          this.$http
            .post('/users/store', formData)
            .then(() => {
              // console.log(res)
              useJwt
                .login({
                  email: this.tel,
                  password: this.password,
                  agent_id: this.ag,
                  Detail: {
                    Ip: this.Ip,
                    UserAgent: navigator.userAgent,
                    Platform: navigator.platform,
                  },
                })
                .then(response => {
                  this.OverLay = false
                  const userData = response.data
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(response.data.refreshToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('CheckClick', true)
                  this.$ability.update(userData.ability)
                  this.$http
                    .get('/auth/checklogin')
                    .then(res => {
                      localStorage.setItem('CountLogin', res.data)
                    })
                  this.$router
                    .push(getHomeRouteForLoggedInUser(userData.role))
                    .catch(error => {
                      this.OverLay = false
                      this.$refs.loginForm.setErrors(error.response.data.error)
                    })
                })
                .catch(error => {
                  this.OverLay = false
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.OverLay = false
              this.SwalError(error.response.data.message)
              if (error.response.data.message === 'ท่านกรอกชื่อ-นามสกุลไม่ตรงกับบัญชีค่ะ กรุณากดยืนยันข้อมูลอีกครั้ง') {
                this.name = error.response.data.data.name
                this.surname = error.response.data.data.surname
              }
            })
        }
      })
    },
    getOTPs() {
      if (this.tel.length < 10) {
        this.SwalError(
          'กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก',
        )
      } else {
        const formData = {
          tels: this.tel,
          agent_id: this.ag,
        }
        this.$http
          .post('/otp/checktel', formData)
          .then(response => {
            this.ref = response.data.ref
            this.trueaccno = this.tel
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    checkOTP() {
      if (this.code === this.otp) {
        this.match = true
      } else {
        this.$refs.captcha.refreshCaptcha()
        this.SwalError('รหัส Captcha ไม่ถูกต้อง')
      }
      // const formData = {
      //   tels: this.tel,
      //   ref: this.ref,
      //   otps: this.otp,
      //   agent_id: this.ag,
      // }
      // this.$http
      //   .post('/otp/check', formData)
      //   .then(response => {
      //     this.match = response.data.match
      //     this.username = response.data.username
      //     // this.Success('ยืนยัน OTP สำเร็จ')
      //     this.confirmOTP()
      //   })
      //   .catch(error => {
      //     this.SwalError(error.response.data.message)
      //   })
    },
    select(option) {
      this.$refs['my-modal'].hide()
      this.selected = option
      this.s_bank = option.bankcode
    },
    getBank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          // console.log(response.data)
          this.bank = response.data
        })
        .catch(error => {
          console.log(error.response.data.message)
        })
    },
    sendOTP() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">ส่ง OTP สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    confirmOTP() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">ยืนยันรหัส OTP สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    SwalError(message) {
      this.$swal({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 5000,
        background: '#202124',
      })
    },
  },
}
</script>

<style scoped>
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.card-body {
  padding: 0 !important;
}
.bank_icon {
  width: 32px;
  border-radius: 15rem;
  padding: 5px;
  margin-right: 10px;
}
.select_bank {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 45px;
  border-radius: 14px;
  background-color: #000000;
  border: 1px solid #000000;
  max-width: 100%;
  padding: 0.2rem 0.7rem;
  filter: drop-shadow(0 0 1px #5c5c5c);
}

label {
  font-size: 1.01rem !important;
  font-weight: 300 !important;
  color: #fff !important;
}

.card {
  background: #00000071 !important;
  border-radius: 14px !important;
  filter: drop-shadow(0 0 1px #5c5c5c);
}

/* .btn-theme-two {
  background: linear-gradient(180deg, #5a499d, #bd6aaa);
  border-radius: 10px;
  height: 38px;
  color: #ffffff !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  padding: 0.3rem 0.8rem !important;
  border-color: #f2994a !important;
} */
.btn2 {
  font-size: 1rem !important;
}

.form-control {
  background: #ffffff !important;
  border-radius: 14px !important;
  color: #232323 !important;
  height: 38px !important;
  font-size: 1.1rem !important;
  filter: drop-shadow(0 0 1px #f2994a);
}
.selected {
  background: #000000 !important;
  border-radius: 14px !important;
  color: #232323 !important;
  height: 38px !important;
  font-size: 1.2rem !important;
  filter: drop-shadow(0 0 1px #f2994a);
}
.dark-layout .custom-select {
  background-color: #000000;
  border-radius: 14px !important;
  border-color: 0 !important;
  filter: drop-shadow(0 0 1px #5c5c5c);
}
</style>

  <style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
